import React, { useState, useEffect } from 'react';
import './Auth.css';

function Auth(props) {

	const authenticateWithSpotify = () => {
		window.location.href = 'https://us-central1-jh-kiwi-auth.cloudfunctions.net/spotifyAuth';
	};

	return (
		<div className="login-wrapper">
			<img src="./logo.svg" />
			<h1>Kiwi</h1>
			<div><button onClick={authenticateWithSpotify}>Authenticate with Spotify</button></div>
			{/*<div><button onClick={authenticateWithSpotify}>Continue without sync</button></div>*/}
		</div>
	);
}

export default Auth
