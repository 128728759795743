import React from 'react';
import './Playlist.css';

function Playlist(props) {

	//const checkCurrentTrack = setInterval(() => {
	//	getCPT();
	//}, 2000); check every 1 - 15 seconds! you have a mystery limit for every 30s, but you probably won't cap it...

	//const getNotesForCurrentTrack = async (trackJSON) => {
	//	console.log(trackJSON);
	//	const playlistID = trackJSON.context.uri.split(":")[2];
	//	const docRef = doc(db, "playlists", playlistID);
	//	const docSnap = await getDoc(docRef);
	//	if (docSnap.exists()) {
	//		console.log("Document data:", docSnap.data());
	//	} else {
	//		console.log("No such document!");
	//	}
	//};

	const userIsViewingHomePage = window.location.pathname == "/";

	const viewHomePage = () => {
		window.location.href = "/";
	};

	const userIsViewingAppDetails = window.location.pathname == "/info";

	const viewAppDetails = () => {
		window.location.href = "/info";
	};
	const stopViewingAppDetails = () => {
		window.location.href = "/";
	};
	
	const userIsViewingPlaylist = window.location.pathname.indexOf("/playlist") >= 0;

	const viewPlaylist = () => {
		window.location.href = "/info";
	};
	const stopViewingPlaylist = () => {
		window.location.href = "/";
	};

	const userIsViewingMyMusic = window.location.pathname == "/no";

	const viewMyMusic = () => {
		window.location.href = "/no";
	};
	const stopViewingMyMusic = () => {
		window.location.href = "/";
	};

	const getCPT = () => {//run this on load to determine if you need to re-authenticate!!!

		var url = new URL(window.location.href);
		let accessToken = url.searchParams.get("accessToken") || localStorage.getItem("kiwiAccessToken");

		let myHeaders = new Headers();
		myHeaders.append("Referer", "");
		myHeaders.append("Authorization", "Bearer "+accessToken);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		fetch(("https://api.spotify.com/v1/me/player/currently-playing"), requestOptions)
			.then(response => response.text())
				.then(result => {
					console.log(JSON.parse(result));//getNotesForCurrentTrack(JSON.parse(result));
				}).catch(error => {
					console.log('error', error);
				});
	};

	return (
		<>
			<div className="bg-gradient">
				<img src="/assets/gradient_kc1.png" alt="" className="" />
			</div>

			<main>
				
				<div className="section-header single-line home-header">
					<div className="home-header-content">
						<div className="back-button" onClick={viewHomePage}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_17_156)"><path d="M15 6L9 12L15 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_17_156"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
						</div>
					</div>
				</div>
				<div className="playlist-header">
					<img src="/assets/covers/kc1.png" alt="" className="" />
					<div className="playlist-details">
						<h1>made for kara</h1>
						<p>Some info</p>
						<p>Josef Halcomb</p>
						{/*<p>Likes / Duration</p>*/}
					</div>
				</div>
				
				<div className="section-header playlist-controls-wrapper">
					<div className="playlist-controls">
						<div className="single-playlist-control thing">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_17_168)"><path d="M12 5V19" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 12H19" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_17_168"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
						</div>
						<div className="single-playlist-control thing">
							<svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" class="Svg-sc-ytk21e-0 bneLcE"><path d="M3 8a1 1 0 0 1 1-1h3.5v2H5v11h14V9h-2.5V7H20a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8z"></path><path d="M12 12.326a1 1 0 0 0 1-1V3.841l1.793 1.793a1 1 0 1 0 1.414-1.414L12 .012 7.793 4.22a1 1 0 1 0 1.414 1.414L11 3.84v7.485a1 1 0 0 0 1 1z"></path></svg>
						</div>
						<div className="single-playlist-control thing">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_27_852)"><path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_27_852"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
						</div>
					</div>
					<div className="single-playlist-control play">
						<img src="/assets/controls/L.png"/>
					</div>
				</div>
				
				<div className="track-list">
					<div className="track">
						<div className="track-card">
							<img src="/assets/covers/kc1.png"/>
							<div className="track-info">
								<p>title</p>
								<p>album</p>
							</div>
							<div className="actions">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_17_140)"><path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_17_140"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
							</div>
						</div>
						<div className="track-notes">
							<p>some notes...</p>
							<p>LET THEM ADD THEIR OWNNNNN/</p>
							{/*<hr/>*/}
						</div>
					</div>
					<div className="track">
						<div className="track-card">
							<img src="/assets/covers/kc1.png"/>
							<div className="track-info">
								<p>title</p>
								<p>album</p>
							</div>
							<div className="actions">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_17_140)"><path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_17_140"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
							</div>
						</div>
						<div className="track-notes">
							<p>some notes...</p>
						</div>
					</div>
				</div>

				<div className="">
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
				</div>
				
			</main>

			<footer>
				<div className="play-controller">
					<div className="details">
						<img src="/assets/queen.jpg" alt="" className="" />
						<div className="text">
							<span>Westbound Bart</span>
							<span>Not Synced</span>
						</div>
						<div className="controls">
							{props.token ? <div><button onClick={getCPT}><svg className="synced" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M354.9 121.7c13.8 16 36.5 21.1 55.9 12.5c8.9-3.9 18.7-6.2 29.2-6.2c39.8 0 72 32.2 72 72c0 4-.3 7.9-.9 11.7c-3.5 21.6 8.1 42.9 28.1 51.7C570.4 276.9 592 308 592 344c0 46.8-36.6 85.2-82.8 87.8c-.6 0-1.3 .1-1.9 .2H504 144c-53 0-96-43-96-96c0-41.7 26.6-77.3 64-90.5c19.2-6.8 32-24.9 32-45.3l0-.2v0 0c0-66.3 53.7-120 120-120c36.3 0 68.8 16.1 90.9 41.7zM512 480v-.2c71.4-4.1 128-63.3 128-135.8c0-55.7-33.5-103.7-81.5-124.7c1-6.3 1.5-12.8 1.5-19.3c0-66.3-53.7-120-120-120c-17.4 0-33.8 3.7-48.7 10.3C360.4 54.6 314.9 32 264 32C171.2 32 96 107.2 96 200l0 .2C40.1 220 0 273.3 0 336c0 79.5 64.5 144 144 144H464h40 8zM433 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L433 241z"/></svg></button></div> : <div><svg className="failed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M354.9 121.7c13.8 16 36.5 21.1 55.9 12.5c8.9-3.9 18.7-6.2 29.2-6.2c39.8 0 72 32.2 72 72c0 4-.3 7.9-.9 11.7c-3.5 21.6 8.1 42.9 28.1 51.7C570.4 276.9 592 308 592 344c0 46.8-36.6 85.2-82.8 87.8c-.6 0-1.3 .1-1.9 .2H504 144c-53 0-96-43-96-96c0-41.7 26.6-77.3 64-90.5c19.2-6.8 32-24.9 32-45.3l0-.2v0 0c0-66.3 53.7-120 120-120c36.3 0 68.8 16.1 90.9 41.7zM512 480v-.2c71.4-4.1 128-63.3 128-135.8c0-55.7-33.5-103.7-81.5-124.7c1-6.3 1.5-12.8 1.5-19.3c0-66.3-53.7-120-120-120c-17.4 0-33.8 3.7-48.7 10.3C360.4 54.6 314.9 32 264 32C171.2 32 96 107.2 96 200l0 .2C40.1 220 0 273.3 0 336c0 79.5 64.5 144 144 144H464h40 8zM433 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L433 241z"/></svg></div>}
							{/*{props.token ? <div><button onClick={getCPT}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M48 432L336 256 48 80l0 352zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z"/></svg></button></div> : <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48 112l0 288H96V112H48zM0 112C0 85.5 21.5 64 48 64H96c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112zm224 0V400h48V112H224zm-48 0c0-26.5 21.5-48 48-48h48c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H224c-26.5 0-48-21.5-48-48V112z"/></svg></div>}*/}
							{props.token ? <div><button onClick={getCPT}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg></button></div> : <div><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg></button></div>}
						</div>
					</div>
					<div className="scrub-bar">
						<div className="scrub-bar-progress">
						</div>
					</div>
				</div>
				<div className="footer">
					<span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_27_815)"><path d="M20.4201 10.1797L12.7101 2.2997C12.6172 2.20598 12.5066 2.13158 12.3847 2.08081C12.2628 2.03004 12.1321 2.00391 12.0001 2.00391C11.8681 2.00391 11.7374 2.03004 11.6155 2.08081C11.4937 2.13158 11.3831 2.20598 11.2901 2.2997L3.58012 10.1897C3.39355 10.3778 3.24621 10.601 3.14664 10.8465C3.04708 11.092 2.99727 11.3548 3.00012 11.6197V19.9997C2.99934 20.5116 3.19489 21.0043 3.54649 21.3764C3.89809 21.7485 4.37898 21.9715 4.89012 21.9997H19.1101C19.6213 21.9715 20.1021 21.7485 20.4537 21.3764C20.8053 21.0043 21.0009 20.5116 21.0001 19.9997V11.6197C21.0009 11.0826 20.7929 10.5663 20.4201 10.1797V10.1797ZM10.0001 19.9997V13.9997H14.0001V19.9997H10.0001ZM19.0001 19.9997H16.0001V12.9997C16.0001 12.7345 15.8948 12.4801 15.7072 12.2926C15.5197 12.1051 15.2653 11.9997 15.0001 11.9997H9.00012C8.7349 11.9997 8.48055 12.1051 8.29301 12.2926C8.10547 12.4801 8.00012 12.7345 8.00012 12.9997V19.9997H5.00012V11.5797L12.0001 4.4297L19.0001 11.6197V19.9997Z" fill="white"/></g><defs><clipPath id="clip0_27_815"><rect width="24" height="24" fill="white"/></clipPath></defs></svg></span>
					<span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_27_836)"><path d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z" stroke="#B3B3B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M20 20L16 16" stroke="#B3B3B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></g><defs><clipPath id="clip0_27_836"><rect width="24" height="24" fill="white"/></clipPath></defs></svg></span>
					<span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="4" y1="3" x2="4" y2="21" stroke="#B3B3B3" strokeWidth="2"/><line x1="9" y1="3" x2="9" y2="21" stroke="#B3B3B3" strokeWidth="2"/><line x1="13.5948" y1="2.92393" x2="20.9241" y2="20.6173" stroke="#B3B3B3" strokeWidth="2"/></svg></span>
				</div>
			</footer>
		</>
	);
}

export default Playlist
