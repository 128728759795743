import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';

import Home from './Home'
import Auth from './Auth'
import KiwiPlayback from './KiwiPlayback'

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
	apiKey: process.env.FIREBASE_APIKEY,
	authDomain: process.env.FIREBASE_AUTHDOMAIN,
	projectId: process.env.FIREBASE_PROJECTID,
	storageBucket: process.env.FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
	appId: process.env.FIREBASE_APPID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if ("serviceWorker" in navigator) {
	window.addEventListener("load", function () {
		navigator.serviceWorker
			.register("serviceWorker.js")
			.then(res => console.log("service worker registered"))
			.catch(err => console.log("service worker not registered", err))
	})
}

function App() {

	const [accessToken, setAccessToken] = useState(false);

	useEffect(() => {
		
		const url = new URL(window.location.href);
		const accessToken = url.searchParams.get("accessToken") || localStorage.getItem("kiwiAccessToken");

		if (!accessToken) {
			console.log("No token found, please authenticate.");
			setAccessToken(false);
			return;
		}
		
		localStorage.setItem("kiwiAccessToken", accessToken);
		setAccessToken(accessToken);

	}, []);

	return (
		<>{accessToken ? <Home token={accessToken} /> : <Auth token={accessToken} />}</>
	);
}

export default App;
