import React, { useState, useEffect } from 'react';

const track = {
    name: "",
    album: {
        images: [
            { url: "" }
        ]
    },
    artists: [
        { name: "" }
    ]
}

function KiwiPlayback(props) {

	//const checkCurrentTrack = setInterval(() => {
	//	getCPT();
	//}, 2000);//2 seconds jut to make fewer calls... I should use the SDK to add a listener which would trigger that...
	
	const getCPT = () => {

		var url = new URL(window.location.href);
		let bearer = url.searchParams.get("accessToken");

		let myHeaders = new Headers();
		myHeaders.append("Referer", "");
		myHeaders.append("Authorization", "Bearer "+bearer);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		fetch(("https://api.spotify.com/v1/me/player/currently-playing"), requestOptions)
			.then(response => response.text())
				.then(result => {

					//getNotesForCurrentTrack(JSON.parse(result));
					console.log(JSON.parse(result));

				}).catch(error => {

					console.log('error', error);
					//if...
					//
					//{
					//    "error": {
					//        "status": 401,
					//        "message": "The access token expired"
					//    }
					//}
					//
					//window.location.href = 'https://us-central1-jh-kiwi-auth.cloudfunctions.net/spotifyAuth';//just assuming it's an auth issue... how can I keep the same page and state though? robably localstorage!!! easy peasy my man...
				});
	};

	return (
		<>
			<br/>
			{props.token ? <div><button onClick={getCPT}>Get CPT</button></div> : <div>n/a</div>}
			<br/>
		</>
	);
}

export default KiwiPlayback
